@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

// debugging class
.test {
  border: 1px solid red;
}
.ant-layout div:has(> .ant-layout-content) {
  padding: 0 !important;
}

.ant-notification-notice.custom-one-line {
  min-width: 384px;
  width: unset !important;
  text-wrap: nowrap;
}

// ghi đè font-weight của ant form cho toàn bộ dự án
.ant-form {
  label {
    font-weight: bold;
    &.ant-radio-wrapper {
      font-weight: unset;
    }
  }
}

// button
.ant-btn-primary {
  border: 1px solid #215493;
}

// suffix input clear all icon ref at #94438
.ant-input-suffix {
  display: none !important;
}

.ant-form-item-control-input-content:hover .ant-input-suffix {
  display: flex !important;
}

// Ghi đè style cho thanh scroll của TextArea
textarea::-webkit-scrollbar {
  width: 7px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #888; /* Màu của thanh cuộn */
  border-radius: 3px;
}

textarea::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Màu khi hover */
}
textarea::-webkit-scrollbar-track {
  background: #f1f1f1; /* Màu của track */
}

.ant-layout-header {
  z-index: 1500;
}

body:has(.ant-modal-mask) {
  .ant-layout-header {
    z-index: 1000;
  }
}

body:has(.ant-image-preview-root) {
  .ant-layout-header {
    z-index: 1000;
  }
}

// button
.ant-btn-primary {
  border: 1px solid #215493;
}
